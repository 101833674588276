.home-steps {
  position: relative;
  scroll-snap-type: y proximity;

  .home-steps-header {
    width: 100%;
    height: 140px;

    .home-steps-header-content {
      width: 100%;
      height: 140px;
      z-index: 2;

      display: flex;
      align-items: center;
      justify-content: center;

      .home-steps-nums {
        display: flex;

        .step-num {
          width: 36px;
          height: 36px;
          margin: 0 12px;
          border-radius: 100%;
          background-color: #82cce8;

          display: flex;
          align-items: center;
          justify-content: center;

          color: white;
          transition: all 0.25s ease-in;

          span {
            transition: all 0.25s ease-in;
          }

          &.current {
            transform: scale(1.3);
            background-color: #379bc2;

            span {
              font-size: 1.35rem;
              font-weight: 600;
            }
          }

          &.past {
            background-color: #29a27e;
          }
        }
      }
    }
  }

  .home-step-skip {
    width: 200px;
    height: 60px;

    background: #fcb542;
    border-radius: 43px;

    right: -20px;
    top: 150px;
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      text-align: center;
      color: #ffffff;
      margin-right: 10px;
    }

    svg {
      width: auto;
      height: 60%;
    }
  }

  .home-step {
    width: 100%;
    z-index: 1;

    .home-step-content {
      width: 100%;
      max-width: 900px;
      background-color: #fff;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .home-step-icon {
        width: 50%;
        min-width: 290px;
      }

      .home-step-title,
      .home-step-separator,
      .home-step-description {
        width: 50%;
        min-width: 320px;
      }

      .home-step-icon {
        height: 12vh;
        display: flex;
        justify-content: center;

        > svg {
          width: auto;
          height: 100%;
        }
      }

      .home-step-title {
        font-weight: 700;
        line-height: 2.4375rem;
        text-align: center;
        color: #5e5f5f;
        margin-top: 2vh;
      }

      .home-step-description {
        line-height: 1.8125rem;
        text-align: center;
        color: #3c3c3c;
        margin-top: 2vh;
      }

      .home-step-separator {
        border-bottom: 1px solid #000000;
        position: absolute;
        bottom: 0;
      }

      .home-step-buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;

        padding-bottom: 46px;

        .home-begin,
        .home-how-to {
          border-radius: 43px;
          color: #ffffff;
          position: relative;
        }

        .home-begin {
          width: 235px;
          height: 65px;
          margin: 0 auto;
          margin-bottom: 48px;

          background: #54b4e0;
          box-shadow: none;
        }

        .home-how-to {
          width: 290px;
          height: 45px;
          background: #a0a0a0;
          text-transform: initial;

          > h6 {
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
}
