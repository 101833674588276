.home-what-skills {
  background: #fccb83;

  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    width: 90%;
    height: auto;
    margin: 0 auto;
  }

  > h6 {
    width: 60%;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 24px;
    text-align: center;
    color: #1e1e1e;
  }
}
