.questionable-display {
  width: 100%;
  height: auto;
  flex: 1;
  padding-bottom: 12vh;

  .questionable-form {
    .MuiFormControl-root {
      width: 100%;
    }

    .questionable-submit,
    .questionable-submit:hover {
      width: 160px;
      height: 160px;

      position: fixed;

      box-shadow: none;
      background: #f29c00;

      .MuiTypography-root {
        font-weight: 600;
        text-transform: initial;
        color: #373737;
      }
    }

    .open-text-field {
      .MuiInputBase-root {
        background: #ffffff;
        border: 3px solid #f29c00;
        border-radius: 13px;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }
  }

  .questionable-mo-form {
    width: 100%;

    .questionable-mo-option-container {
      display: flex;
      flex-direction: column;

      .questionable-mo-option {
        margin: 3vh 0;
        display: flex;

        .questionable-mo-option-radio {
          width: 25%;
          padding: 0;
        }

        .questionable-mo-option-text {
          flex: 1;
          line-height: 1.5625rem;
          color: #202020;
        }
      }

      .open-text-field {
        width: 70%;
        align-self: center;
      }
    }
  }

  .questionable-rating-form {
    width: 76%;
    margin: 0 auto;

    .scale-helper {
      width: 200px;
      height: 200px;
      border-radius: 100%;
      background-color: #db8803;

      position: fixed;
      left: 50%;
      top: 10%;
      transform: translate(-50%, 0);
      z-index: 2;

      display: flex;
      justify-content: center;
      align-items: center;

      .scale-helper-label {
        line-height: 1.5625rem;
        text-align: center;
        color: #ffffff;
        position: relative;
        width: 80%;
      }

      .scale-helper-indicator {
        position: absolute;
        border-radius: 100%;
        background-color: #a54605;
      }
    }

    .rating-form-field {
      width: 100%;
      margin: 1vh 0;

      .rating-form-control {
        width: 100%;
        display: flex;
        flex-direction: column;

        .rating-form-radio-group {
          width: 100%;
          height: 96px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .rating-form-control-label {
            margin: 0;
            align-items: end;

            .MuiRadio-root.MuiButtonBase-root {
              padding: 0;

              .rating-level-option {
                width: auto;
                height: 48px;
                transform-origin: 50% 100%;

                transition: all 0.25s ease-in;

                &.checked {
                  transform: scaleY(1.75);
                }
              }
            }
          }
        }
      }
    }

    .rating-form-field-label {
      margin-top: 2vh;

      h6 {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.5625rem;
        color: #000000;
      }
    }
  }

  .questionable-open-text-form {
    width: 76%;
    margin: 0 auto;

    .open-text-slider {
      width: 100%;
      margin-top: 40px;
      color: #1e85a5;
    }

    > .MuiFormControl-root {
      margin-top: 76px;
    }

    .MuiSlider-valueLabelOpen {
      font-size: 3.7125rem;
    }
  }
}
