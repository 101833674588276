.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleY(-1);

  > .MuiBottomNavigation-root {
    transform: scaleY(-1);
  }
}
