.dashboard {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .dashboard-modal-quote {
    width: 96%;
    height: 70%;

    background: #041714;
    mix-blend-mode: normal;
    opacity: 0.9;
    border-radius: 50px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    padding: 60px 40px;
    pointer-events: none;

    .dashboard-modal-quote-title {
      font-weight: 700;
      line-height: 30px;
      color: #5ae8ce;
      margin-bottom: 2vh;
    }

    .dashboard-modal-quote-text {
      font-weight: 500;
      line-height: 38px;
      color: #ffffff;

      span {
        font-style: italic;
        font-weight: 300;
        text-align: right;
      }
    }
  }

  .dashboard-title {
    font-family: 'Baumans', 'Roboto', 'Helvetica', 'Arial',
      sans-serif;
    line-height: 57px;
    text-align: center;
    padding-top: 4vh;
  }

  .dashboard-content {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .dashboard-switch-group {
      position: absolute;
      top: 20px;
      right: 28px;
      z-index: 1;

      .MuiFormControlLabel-root {
        margin: 0;

        .dashboard-switch {
          width: 60px;
          height: 30px;
          padding: 0;

          .MuiSwitch-switchBase {
            padding: 4px;

            &.Mui-checked {
              transform: translateX(30px);
            }

            .MuiSwitch-thumb {
              width: 22px;
              height: 22px;
              background: #fcb542;
              border-radius: 100%;
              box-shadow: none;
            }
          }

          .MuiSwitch-track {
            background: #4b5154;
            opacity: 1;
            border-radius: 14px;
          }
        }
      }
    }
  }

  .dashboard-footer {
    width: 100%;
    height: 20vh;

    display: flex;

    .dashboard-footer-leaves {
      width: 32%;
      display: flex;
      justify-content: center;

      > svg {
        width: auto;
        height: 100%;
      }
    }

    .dashboard-footer-phrase {
      border-right: 4px solid rgba(32, 163, 126, 0.35);
      width: calc(68% - 20px);

      * {
        pointer-events: none;
      }

      .dashboard-footer-title {
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        padding-right: 10px;

        p {
          padding-right: 4px;
          font-weight: 700;
          line-height: 19px;
          color: #20a37e;
          pointer-events: none;
        }

        svg {
          width: 22px;
          height: 22px;

          circle {
            fill: #20a37e;
          }
        }
      }

      .dashboard-footer-quote {
        font-weight: 500;
        line-height: 19px;
        color: #373737;
        padding: 6px;
        padding-left: 20px;
        height: calc(20vh - 20px);
        overflow-y: scroll;

        span {
          font-style: italic;
          text-align: right;
        }
      }
    }
  }
}
