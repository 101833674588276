.rating-stepper {
  width: 100%;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  .rating-stepper-steps {
    position: relative;

    .rating-stepper-step {
      width: 12px;
      height: 12px;
      position: absolute;

      border-radius: 0;
      transition: all 0.25s ease-in;

      &.selected {
        border-radius: 100%;
        transform: scale(1.65);
      }
    }

    .rating-stepper-path {
      width: 30px;
      position: absolute;
      left: 28px;
      top: 0;

      .rating-stepper-line {
        fill: none;
        stroke-width: 2px;
        stroke: #fff;
      }
    }
  }
}
