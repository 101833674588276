.mo-stepper {
  width: 100%;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  .mo-stepper-steps {
    height: 50px;

    display: flex;
    align-items: center;
    position: relative;

    .mo-stepper-step-selected,
    .mo-stepper-step {
      width: 22px;
      height: 22px;
      margin: 0 13px;
    }

    .mo-stepper-step {
      background: #f29c00;
      border-radius: 100%;
    }

    .mo-stepper-path {
      height: 50px;
      position: absolute;
      top: 0;
      left: 0;

      .mo-stepper-line {
        fill: none;
        stroke-width: 2px;
        stroke: #fff;
      }
    }
  }
}
