.home-header {
  width: 100%;
  height: 140px;
  position: fixed;
  left: 0;
  top: 0;

  .MuiPaper-root {
    width: 100%;
    height: 100%;

    .home-header-container {
      width: 100%;
      height: 100%;
      max-width: 900px;
      margin: 0 auto;

      display: flex;
      flex-direction: row;
    }

    .home-header-logo {
      width: 36%;
      min-width: 144px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .header-start-img {
      width: 38%;
      min-width: 140px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 70px;
        height: auto;
      }
    }

    .home-header-text {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      position: relative;

      color: #5e5f5f;

      .home-header-title {
        font-style: normal;
        font-weight: 600;
        font-size: 2.25rem;
        line-height: 44px;
        color: #5e5f5f;
      }

      .home-header-subtitle {
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 24px;
        span {
          color: #f29c00;
        }
      }

      > h5 {
        font-weight: 600;
        line-height: 29px;
      }
    }

    .home-header-down {
      position: absolute;
      bottom: 8%;
      left: 32%;
      transform: translate(-50%, 0);
    }
  }
}
