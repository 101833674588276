.dashboard-circle {
  width: 84vw;
  height: 84vw;
  max-width: 450px;
  max-height: 450px;
  border-radius: 100%;
  background: #d9d9d9;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .dashboard-first-time {
    width: calc(100% - 14px);
    height: calc(100% - 14px);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(4, 23, 20, 0.7);
    border-radius: 100%;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: initial;

    svg {
      width: 44px;
      height: auto;
      margin-top: 14vh;
    }

    .MuiTypography-root {
      width: 60%;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      margin-top: 4vh;
    }
  }
}
