.about {
  width: 100vw;
  height: 100vh;

  overflow-x: hidden;

  .about-intro {
    width: 100%;
    height: 84vh;

    background-color: #fcb542;

    display: flex;
    flex-direction: column;
    align-items: center;

    .MuiPaper-root {
      width: 74%;
      margin-top: 8vh;
      border-radius: 26px;
      padding: 12px;

      display: flex;
      flex-direction: column;
      align-items: center;

      .bision-logo {
        width: auto;
        height: 9vh;
        margin-top: 2vh;
      }

      .MuiTypography-root {
        text-align: center;
        line-height: 29px;
      }
    }
  }

  .about-section {
    width: 88%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    > svg {
      width: 100%;
      height: auto;
      margin-top: 3vh;
    }

    > svg.about-img-b {
      margin: 0;
    }

    .about-section-txt {
      max-width: 326px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #1e1e1e;
      margin-top: 2vh;
      margin-bottom: 4vh;
    }

    .MuiDivider-root {
      width: 64vw;
      border: 0.5px solid #000000;
      margin: 0.5vh 0;
    }

    &.level-section {
      > svg {
        width: auto;
        height: 12vh;
        margin-top: 6vh;
        margin-bottom: 2vh;
      }

      .about-level-title {
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        color: #5e5f5f;
      }

      .about-level-description {
        font-weight: 400;
        line-height: 29px;
        text-align: center;
        color: #3c3c3c;
        margin: 3vh 0;
      }
    }
  }
}
