.how-to {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  overflow-x: hidden;

  .how-to-section {
    width: 76%;
    display: flex;
    flex-direction: column;

    .how-to-video {
      width: 100%;
      position: relative;

      img {
        width: 120%;
        height: auto;
        position: relative;
        left: -10%;
      }
    }

    .how-to-logo {
      text-align: center;
      margin-top: 9vh;
      margin-bottom: 3vh;

      .bision-logo {
        width: 50px;
        height: auto;
      }
    }

    .how-to-title {
      font-weight: 700;
      line-height: 38px;
      text-align: center;
      color: #5e5f5f;

      margin-top: 5vh;
      margin-bottom: 3vh;
    }

    .how-to-subtitle,
    .how-to-description {
      font-weight: 400;
      line-height: 28px;
      text-align: center;
    }

    .how-to-subtitle {
      color: #4890ac;
    }

    .how-to-description {
      color: #3c3c3c;
      margin-bottom: 5vh;
    }

    .MuiDivider-root {
      border-color: #000;
      border-bottom-width: 0.5px;
    }
  }
}
