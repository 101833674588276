.wellness-circle-container,
.wellness-circle {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
}

.wellness-circle-container {
  .wellness-circle {
    z-index: 1;
  }

  .wellness-circle-slice {
    fill: none;
    stroke: #2097a0;
    stroke-width: 3px;
    z-index: 1;
  }

  .wellness-icons {
    transform-origin: 50% 50%;
    transform: translate(2%, -1.5%) scale(0.9);
  }
}

.wellness-circle-labels {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;

  .wellness-circle-label {
    width: 110px;
    display: block;
    font-weight: 500;
    line-height: 0.9375rem;
    text-align: center;
    color: #373737;

    position: absolute;
    transform: translate(-50%, -50%);
  }
}
