.thermometer {
  width: 40%;
  height: 40%;
  z-index: 3;

  border-radius: 100%;
  background: #baede1;
  border: 3px solid #2097a0;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  > .MuiTypography-root {
    width: 90%;
    text-align: center;

    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.125;
    color: #1e85a5;
  }

  .thermometer-scale,
  .thermometer-needle,
  .thermometer-title {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .thermometer-scale {
    width: 72%;
    height: auto;
    top: 12%;
  }

  .thermometer-needle {
    width: 28%;
    height: auto;
    top: 15%;
    transform-origin: 50% 70% !important;
  }

  .thermometer-title {
    width: 48%;
    height: auto;
    top: 80%;
  }
}
