.appbar-toolbar {
  .appbar-open-nav {
    padding: 0;

    svg {
      width: 100%;
    }
  }

  .appbar-user {
    flex: 1;

    font-weight: 600;
    line-height: 1.8125rem;
    text-align: center;
    color: #373737;
  }

  .appbar-logo {
    display: flex;
    justify-content: flex-end;

    .profile-button {
      svg {
        width: 36px;
        height: auto;
      }
    }
  }
}
