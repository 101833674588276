.home.page {
  width: 100%;
  overflow-x: hidden;

  .home-main {
    background: #fff;

    .home-welcome {
      width: 100%;
      background: #d34f7a;
      display: flex;
      flex-direction: column;

      .home-welcome-text {
        width: 75%;
        padding: 14px 22px;
        border-radius: 25px;
        margin: 0 auto;

        > h6 {
          font-weight: 400;
          font-size: 1.25rem;
          line-height: 24px;
          text-align: center;
          color: #3c3c3c;
        }
      }

      .home-cta-down {
        text-align: center;

        > p {
          color: #ffffff;
          margin-top: 30px;
          line-height: 18px;
        }
      }
    }
  }
}
