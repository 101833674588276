.login-email-sent {
  padding: 0 24px;

  .login-email-sent-title {
    font-weight: 700;
    text-align: center;
    color: #737373;
    margin: 4vh 0;
  }

  .login-email-sent-description {
    text-align: center;
    color: #353535;
    line-height: 1.125rem;
  }
}

.login-form {
  padding: 0 24px;

  .email-field {
    margin-top: 6vh;

    @media (orientation: landscape) {
      margin-top: 2vh;
    }

    .MuiInputLabel-root {
      font-weight: 300;
      color: #858585;
      padding-left: 12px;
    }

    .MuiInput-root {
      font-size: 0.8175rem;
      padding-left: 12px;
      margin-top: 1.125rem;

      &::before,
      &::after {
        border-bottom: 2px solid #54b4e0 !important;
      }

      .MuiButtonBase-root.MuiIconButton-root {
        padding: 0;
      }
    }
  }

  .MuiFormHelperText-root.Mui-error {
    padding-left: 12px;
  }
}
