.question-display {
  width: 100%;
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  position: absolute;

  .question-header {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 2;

    .question-summary {
      width: 100%;
      height: 12vh;
      padding: 0 10vw;
      background: #fff;

      display: flex;
      align-items: center;

      > svg {
        width: auto;
        height: 72%;
      }

      .progress-circle {
        width: 7%;
        height: auto;
        margin: 0 10px;
      }

      .question-level-title {
        font-weight: 600;
        line-height: 1.5rem;
        color: #7d7d7d;
        flex: 1;
        text-align: center;
        padding: 0 1vw;
      }
    }

    .question-content {
      width: 100%;
      min-height: 16vh;
      padding: 1.5vh 0;

      background: #dcdcf3;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      z-index: 1;

      .question-name {
        font-weight: 600;
        line-height: 1.5rem;
        color: #202020;

        margin-bottom: 12px;
      }

      .question-name,
      .question-instruction {
        width: 82%;
      }
    }
  }

  .celebrate-finish {
    width: 100vw;
    height: calc(100vh - 82px);
    position: fixed;
    background: rgba(0, 23, 135, 0.5);

    display: flex;
    justify-content: center;

    .celebrate-finish-confetti {
      width: 100vw;
      height: calc(100vh - 82px);
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    .celebrate-finish-modal {
      width: 310px;
      height: 310px;
      border-radius: 100%;
      margin-top: 14vh;

      background: radial-gradient(
            36.64% 36.64% at 50% 50%,
            rgba(255, 60, 60, 0.132) 0%,
            rgba(246, 54, 54, 0.138689) 70.83%,
            rgba(164, 0, 0, 0.2) 95.31%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        #f29c00;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .celebrate-finish-close {
      width: 100%;
      height: 84px;

      display: flex;
      justify-content: center;
      align-items: center;

      > svg {
        width: 26px;
        height: auto;
      }
    }

    .celebrate-finish-title,
    .celebrate-finish-description {
      width: 74%;
      color: #ffffff;
      text-align: center;
      line-height: 1.8125rem;
    }

    .celebrate-finish-title {
      font-weight: 300;
    }

    .celebrate-finish-description {
      font-weight: 600;
    }

    .celebrate-finish-icon {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: center;
      margin: 10px 0;

      > svg {
        width: auto;
        height: 100%;
      }
    }
  }
}
