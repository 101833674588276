.home-covid {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  .home-covid-img {
    width: 100%;
  }

  .home-covid-txt-a {
    width: 80%;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: #394277;
  }

  .home-covid-txt-b {
    width: 86%;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #394277;

    &.home-covid-work-txt {
      width: 100%;
      text-align: left;
      padding-left: 20px;
      margin: 0;
    }
  }

  .home-covid-work {
    width: 90%;
    display: flex;

    .home-money-img {
      width: 100%;
    }
  }

  .home-covid-txt-c {
    width: 80%;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: #394277;
  }
}
