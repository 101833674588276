.login {
  width: 100vw;
  height: 100vh;

  background: linear-gradient(
    0deg,
    #7fccea 0%,
    #309ac4 100%
  );

  .login-main {
    height: 100%;

    display: flex;
    justify-content: center;

    @media (orientation: landscape) {
      align-items: center;
    }

    .login-form-container {
      width: 74%;
      height: 66%;
      min-width: 300px;
      min-height: 320px;

      margin-top: 12vh;
      border-radius: 27px;

      display: flex;
      flex-direction: column;
      align-items: center;

      @media (orientation: landscape) {
        margin-top: 0;
      }

      .bision-logo {
        width: 32%;
        max-width: 120px;
        height: auto;
        margin-top: 6vh;
      }

      .login-title {
        font-weight: 600;
        color: #5e5f5f;
        margin-top: 2vh;
      }

      .login-copy {
        width: 74%;
        font-weight: 400;
        line-height: 1.5rem;
        text-align: center;

        color: #5e5f5f;
        margin-top: 2vh;
      }

      .login-form {
        width: 100%;
        flex: 1;
      }
    }
  }
}
