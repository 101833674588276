.MuiCard-root.level-card {
  width: 100%;
  min-height: 13vh;
  margin-bottom: 2vh;

  display: flex;
  flex-direction: column;

  .MuiCardContent-root.level-card-content {
    width: 100%;
    padding: 6px;
    min-height: 13vh;

    padding-bottom: 6px !important;
    display: flex;
    flex: 1;

    .level-progress-circle {
      width: 8%;
    }

    .level-icon {
      width: 24%;
      height: auto;
      display: flex;
      align-items: center;
    }

    .level-summary {
      flex: 1;
      display: flex;
      flex-direction: column;

      .progress-number {
        text-align: right;
      }

      .level-summary-content {
        flex: 1;
        display: flex;
        padding-left: 16px;
        align-items: center;

        .level-summary-title {
          color: #616161;
          line-height: 1.5rem;
          flex: 1;
        }
      }
    }
  }

  .level-summary-collapse {
    width: 100%;

    .MuiCollapse-wrapperInner {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #eef8ff;
      border-radius: 0.625rem;
      padding-bottom: 3vh;

      .MuiDivider-root.MuiDivider-fullWidth {
        width: 92%;
        border-bottom: 1px solid #dcdcdc;
      }

      .sublevel-summary-row {
        width: 94%;
        display: flex;
        align-items: center;
        margin-top: 3vh;

        h6 {
          line-height: 1.5rem;
          color: #616161;
          flex: 1;
          padding: 0 3vw;
        }

        .sublevel-go-button {
          border-radius: 2.125rem;
          text-transform: initial;
          width: 140px;

          span {
            font-weight: 600;
            line-height: 1.1875rem;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.level2-description-modal {
  width: 92vw;
  height: 76vh;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: rgba(23, 4, 22, 0.85);
  mix-blend-mode: normal;
  border-radius: 50px;
  pointer-events: none;
  padding: 0 8vw;
  z-index: 2;

  .level2-description-title {
    font-style: normal;
    font-weight: 700;
    color: #c5adff;
    margin-top: 10vh;
  }

  .level2-description-text {
    font-size: 20px;
    line-height: 29px;
    color: #ffffff;
    margin-top: 4vh;
  }
}
