.evaluations {
  display: flex;
  flex-direction: column;
  align-items: center;

  .evaluations-completed {
    width: 94%;
    margin: 2vh 0;
    background: #dcf3f2;
    border-radius: 14px;
    padding: 4vw;

    .evaluations-completed-text {
      color: #008e77;
    }
  }

  .evaluations-title {
    font-weight: 600;
    line-height: 1.8125rem;
    text-align: center;
    color: #373737;
  }

  .evaluations-list {
    width: 92%;
    margin-top: 3vh;
  }
}
